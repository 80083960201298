import React from 'react';
import type {
  InterfaceContentHeadless,
  InterfaceContentNeMo,
} from '../@types/interfaces/adapterCMSEditor';

import { adapterCMSEditor } from './adapterCMSEditor';
import { generateIframePresetAttributes } from './generateIframePresetAttributes';
import { generateIframeSizingAttributes } from './generateIframeSizingAttributes';

export const convertCMSContentToIframeProps = (
  content: InterfaceContentHeadless | InterfaceContentNeMo,
) => {
  if (!content) {
    return {
      iframeProps: {} as React.DetailedHTMLProps<
        React.IframeHTMLAttributes<HTMLIFrameElement>,
        HTMLIFrameElement
      >,
      presetSizing: -1,
      presetIframe: -1,
    };
  }

  const { preset, sizing, analytics } = adapterCMSEditor(content);
  const attributesPreset = generateIframePresetAttributes(preset);
  const attributesSizing = generateIframeSizingAttributes(sizing);
  return {
    iframeProps: { ...attributesPreset, ...attributesSizing } as unknown as React.DetailedHTMLProps<
      React.IframeHTMLAttributes<HTMLIFrameElement>,
      HTMLIFrameElement
    >,
    presetSizing: sizing.active,
    presetIframe: preset.active,
    analytics,
  };
};

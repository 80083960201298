/**
 * convertHtmlEntitiesToTags
 * If in a browser, will return a string that has converted HTML entities to Tags,
 * eg: `&lt;h1&gt;Heading 1&lt;/h1&gt;` to `<h1>Heading 1</h1>`
 *
 * NOTE: if run in an environment without a global `document` object, it will return the original unaltered string
 */
export const convertHtmlEntitiesToTags = (str: string) => {
  if (typeof document !== 'undefined') {
    const txt = document.createElement('textarea');
    txt.innerHTML = str;
    return { hasConverted: true, text: txt.value };
  }
  return { hasConverted: false, text: str };
};

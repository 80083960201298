import { urlQueryStringToObj } from './urlQueryStringToObj';

export const getQueryStringFromURL = (url: string) => {
  if (url) {
    const urlObj = new URL(url);
    if (urlObj?.search) {
      return urlQueryStringToObj(urlObj.search);
    }
  }
  return null;
};

import type {
  InterfaceAdapterCMSEditorResponse,
  InterfaceContentHeadless,
  InterfaceContentNeMo,
} from '../@types/interfaces/adapterCMSEditor';

export const NOT_SET = '';
export const convertActivePresetToNumber = (activePreset: string) => {
  // NOTE: intentional fallthroughs
  switch (activePreset) {
    case 'Simple':
    case '1':
      return 1;

    case 'Copy Search Query Parameters into `src`':
    case '2':
      return 2;

    case 'Pattern match URL':
    case '3':
      return 3;

    case 'Write HTML':
    case '4':
      return 4;

    default:
      return 1;
  }
};
export const convertActiveSizingToNumber = (activeSizing: string) => {
  // NOTE: intentional fallthroughs
  switch (activeSizing) {
    case 'Method 1':
    case '1':
      return 1;
    case 'Method 2':
    case '2':
      return 2;
    default:
      return 1;
  }
};

export const adapterNeMo = ({
  activePreset,
  activeSizing,
  iframeSrc,
  hostUrlPattern,
  iframeSrcPattern,
  iframeSrcdoc,
  iframeTitle,
  iframeAnalyticsVendorName,
  iframeAnalyticsVendorType,
  sizing,
}: InterfaceContentNeMo): InterfaceAdapterCMSEditorResponse => {
  const presetActive = convertActivePresetToNumber(activePreset);
  const sizingActive = convertActiveSizingToNumber(activeSizing);
  const toReturn = {
    preset: {
      active: presetActive,
      hostUrlPattern,
      iframeSrc: presetActive === 4 ? undefined : iframeSrc,
      iframeSrcdoc,
      iframeSrcPattern,
      iframeTitle,
    },
    sizing: {
      active: sizingActive,
      sizing1: {
        horizontal: sizing['Method 1'].horizontal || '',
        vertical: sizing['Method 1'].vertical || '',
      },
      sizing2: { width: sizing['Method 2'].width || '', height: sizing['Method 2'].height || '' },
    },
  } as InterfaceAdapterCMSEditorResponse;

  if (iframeAnalyticsVendorName || iframeAnalyticsVendorType) {
    toReturn.analytics = {
      vendorName: iframeAnalyticsVendorName || NOT_SET,
      vendorType: iframeAnalyticsVendorType || NOT_SET,
    };
  }
  return toReturn;
};

export const adapterHeadless = ({
  fields,
}: InterfaceContentHeadless): InterfaceAdapterCMSEditorResponse => {
  const active = convertActivePresetToNumber(fields.activePreset);
  const toReturn = {
    preset: {
      active,
      hostUrlPattern: fields.hostUrlPattern,
      // NOTE: `src` should be undefined when using `srcDoc`/`active=4`
      iframeSrc: active === 4 ? undefined : fields.iframeSrc,
      iframeSrcdoc: fields.iframeSrcdoc,
      iframeSrcPattern: fields.iframeSrcPattern,
      iframeTitle: fields.iframeTitle,
    },
    sizing: {
      active: convertActiveSizingToNumber(fields.activeSizing),
      sizing1: {
        horizontal: fields.iframeAspectHorizontal,
        vertical: fields.iframeAspectVertical,
      },
      sizing2: {
        width: fields.iframeWidth,
        height: fields.iframeHeight,
      },
    },
  } as InterfaceAdapterCMSEditorResponse;
  if (fields.iframeAnalyticsVendorName || fields.iframeAnalyticsVendorType) {
    toReturn.analytics = {
      vendorName: fields.iframeAnalyticsVendorName || NOT_SET,
      vendorType: fields.iframeAnalyticsVendorType || NOT_SET,
    };
  }
  return toReturn;
};

export const adapterCMSEditor = (arg: InterfaceContentHeadless | InterfaceContentNeMo) => {
  // @ts-expect-error 'Error' is `__type` doesn't exist on InterfaceContentNeMo
  if (arg?.__type === 'aem-headless') {
    return adapterHeadless(arg as InterfaceContentHeadless);
  }
  return adapterNeMo(arg as InterfaceContentNeMo);
};

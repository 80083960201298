import { matchPath } from 'react-router-dom';
import { urlQueryStringToObj } from './urlQueryStringToObj';
import { urlOriginRemove } from './urlOriginRemove';

export const getDynamicValues = ({
  pattern,
  sourceURL,
}: {
  pattern: string;
  sourceURL: string;
}) => {
  let _sourceURL = sourceURL;
  // adds http(s):// if missing
  if (_sourceURL.match(/http(s)*:\/\//) === null) {
    _sourceURL = `https://${_sourceURL}`;
  }
  const sourceURLObject = new URL(_sourceURL);

  const [patternPathname, patternSearchQueryParams] = pattern.split('?');

  const matchProfile = matchPath(
    {
      path: urlOriginRemove(patternPathname),
    },
    sourceURLObject.pathname,
  );

  const patternSearchQueryParamsObj = urlQueryStringToObj(patternSearchQueryParams);

  const sourceURLSearchQueryParamsObject = urlQueryStringToObj(sourceURLObject.search);
  const sourceURLDynamicSearchQueryParams = {} as Record<string, string>;

  // TODO: MAYBE USE A `set` INSTEAD OF THIS BOOLEAN FLAG?
  let hasQueryParams = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(patternSearchQueryParamsObj)) {
    if (value.indexOf(':') === 0) {
      const searchQueryValue = sourceURLSearchQueryParamsObject[key];
      if (searchQueryValue) {
        hasQueryParams = true;
        sourceURLDynamicSearchQueryParams[value.substring(1)] = searchQueryValue;
      }
    }
  }
  if (hasQueryParams || (matchProfile && matchProfile.params)) {
    return {
      ...(hasQueryParams && sourceURLDynamicSearchQueryParams),
      ...(matchProfile && matchProfile.params),
    } as Record<string, string>;
  }
  return undefined;
};

import type {
  InterfaceGenerateIframePresetAttributes,
  InterfaceGenerateIframePresetAttributesOutput,
} from '../@types/interfaces/generateIframePresetAttributes';

import { urlQueryStringToObj } from './urlQueryStringToObj';
import { urlOriginRemove } from './urlOriginRemove';
import { urlOriginGet } from './urlOriginGet';
import { getQueryStringFromURL } from './getQueryStringFromURL';
import { replaceQueryStringDynamicValues } from './replaceQueryStringDynamicValues';
import { replacePathnameDynamicValues } from './replacePathnameDynamicValues';
import { getDynamicValues } from './getDynamicValues';
import { objToStr } from './objToStr';
import { convertHtmlEntitiesToTags } from './convertHtmlEntitiesToTags';

export const TITLE_DEFAULT_PRESET_UNKNOWN = 'iframe preset unknown';
export const TITLE_DEFAULT_PRESET_1 = 'iframe preset 1';
export const TITLE_DEFAULT_PRESET_2 = 'iframe preset 2';
export const TITLE_DEFAULT_PRESET_3 = 'iframe preset 3';
export const TITLE_DEFAULT_PRESET_4 = 'iframe preset 4';

/**
 * generateIframePresetAttributes
 *
 * When `active === 1` make sure to set:
 * - `iframeSrc`
 * - `iframeTitle`: optional
 *
 * When `active === 2` make sure to set:
 * - `iframeSrc`
 * - `iframeTitle`: optional
 *
 * When `active === 3` make sure to set:
 * - `hostUrlPattern`
 * - `iframeSrcPattern`
 * - `iframeTitle`: optional
 *
 * When `active === 4` make sure to set:
 * - `iframeSrcdoc`
 * - `iframeTitle`: optional
 */
export const generateIframePresetAttributes = (props: InterfaceGenerateIframePresetAttributes) => {
  const {
    active = -1,
    iframeTitle,
    iframeSrc,
    iframeSrcPattern,
    iframeSrcdoc,
    hostUrlPattern,
  } = props || {};

  let propsPreset = {} as InterfaceGenerateIframePresetAttributesOutput;

  switch (active) {
    // Directly set iframe src
    case 1:
      propsPreset = {
        src: iframeSrc,
        title: iframeTitle || TITLE_DEFAULT_PRESET_1,
        'data-active-preset': active,
      };
      break;

    // Copy search query parameters
    case 2: {
      if (typeof window === 'undefined') {
        propsPreset = {
          title: `${iframeTitle || TITLE_DEFAULT_PRESET_2}`,
          'data-active-preset': active,
        };
        // Early break when in SSR
        break;
      }
      // Should only run in browser
      const [pathname, queryString] = (iframeSrc || '').split('?');
      const srcParams = urlQueryStringToObj(queryString);

      const sourceURL = window?.location.href;
      const parentParams = getQueryStringFromURL(sourceURL);

      // NOTE: not using `new URLSearchParams({ ...srcParams, ...parentParams }).toString()`
      // because Audi uses `=` in Vehicle ID, eg:
      // `...html?vehicleId=VVNBMDVBMTM5NDZkOTFhNDBhMGUwYTk0NGFmNg=&code=...`
      // and using `URLSearchParams` + `toString()` generates:
      // `...html?vehicleId=VVNBMDVBMTM5NDZkOTFhNDBhMGUwYTk0NGFmNg%3D&code=...`
      // which breaks the forms

      const newSearchQueryParameters = objToStr({ ...srcParams, ...parentParams }, '&');
      propsPreset = {
        title: iframeTitle || TITLE_DEFAULT_PRESET_2,
        src: pathname === '' ? undefined : `${pathname}?${newSearchQueryParameters}`,
        'data-active-preset': active,
      };

      break;
    }
    // Pattern matching
    case 3: {
      propsPreset = {
        title: iframeTitle || TITLE_DEFAULT_PRESET_3,
        src: undefined,
        'data-active-preset': active,
      };

      if (typeof window === 'undefined') {
        propsPreset.title = `${propsPreset.title}`;
        // Early break when in SSR
        break;
      }
      // Should only run in browser
      if (hostUrlPattern && iframeSrcPattern) {
        let outputSrcPathname = '';
        let outputSrcQueryString = '';
        let srcPathname = '';

        let sourceURL = '';

        if (typeof window === 'undefined') {
          // When under SSR:
          // Doing nothing since no `sourceURL` or window
          // Can't pattern match
          propsPreset.title = `${propsPreset.title}`;
          break;
        }

        if (typeof window !== 'undefined') {
          sourceURL = window?.location.href;
        }

        const values = getDynamicValues({
          pattern: hostUrlPattern || '',
          sourceURL,
        });

        if (values === undefined) {
          break;
        }

        const [splitSrcPathname, splitSrcQueryString] = (iframeSrcPattern || '').split('?');
        srcPathname = splitSrcPathname;

        outputSrcPathname =
          replacePathnameDynamicValues({
            pathnamePattern: urlOriginRemove(splitSrcPathname),
            values,
          }) || '';

        outputSrcQueryString = replaceQueryStringDynamicValues({
          queryStringPattern: splitSrcQueryString,
          values,
        });

        const newSrc = `${urlOriginGet(srcPathname)}${outputSrcPathname}${
          outputSrcQueryString ? `?${outputSrcQueryString}` : ''
        }`;

        if (newSrc !== '') {
          propsPreset.src = newSrc;
        }
      }

      break;
    }
    // Directly set iframe `srcDoc`
    case 4: {
      if (typeof window === 'undefined') {
        propsPreset = {
          title: iframeTitle || TITLE_DEFAULT_PRESET_4,
          src: undefined,
          srcDoc: undefined,
          'data-active-preset': active,
        };
        // Early break when in SSR
        break;
      }
      // Should only run in browser
      let hasHTML = false;
      let htmlText = '';
      if (iframeSrcdoc) {
        const { hasConverted, text } = convertHtmlEntitiesToTags(iframeSrcdoc);
        hasHTML = hasConverted;
        htmlText = text;
      }
      propsPreset = {
        title: iframeTitle || TITLE_DEFAULT_PRESET_4,
        src: undefined,
        srcDoc: hasHTML ? htmlText : undefined,
        'data-active-preset': active,
      };
      break;
    }
    // Catch-all
    default:
      propsPreset = {
        src: iframeSrc,
        title: iframeTitle || TITLE_DEFAULT_PRESET_UNKNOWN,
        'data-active-preset': active,
      };
      break;
  }

  return propsPreset;
};

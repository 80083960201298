import type { InterfaceGenerateIframeSizing } from '../@types/interfaces/GenerateIframe';

export const generateIframeSizingAttributes = (arg: InterfaceGenerateIframeSizing) => {
  const { active, ...sizing } = arg || {};

  // In case of misuse
  if (!active) {
    return {
      style: {
        width: '100%',
        height: 'auto',
        border: 0,
      },
      'data-active-sizing': 2,
    };
  }

  switch (active) {
    case 2: {
      const activeTwoStyle = { ...sizing.sizing2, border: 0 };
      return {
        style: activeTwoStyle,
        'data-active-sizing': active,
        'data-width': activeTwoStyle.width,
        'data-height': activeTwoStyle.height,
      };
    }
    default: {
      const { horizontal = 1, vertical = 1 } = sizing?.sizing1 || {};
      const aspectRatio = `${Number(horizontal) / Number(vertical)}`;
      return {
        style: {
          width: '100%',
          height: 'auto',
          aspectRatio,
          border: 0,
        },
        'data-active-sizing': active,
        'data-aspect-ratio': aspectRatio,
      };
    }
  }
};

import type { InterfaceReplaceQueryStringDynamicValues } from '../@types/interfaces/replaceQueryStringDynamicValues';

export const replaceQueryStringDynamicValues = (arg: InterfaceReplaceQueryStringDynamicValues) => {
  const { queryStringPattern, values } = arg || {};
  if (!queryStringPattern) {
    return '';
  }
  const queryStringPatternSplitAmpersand = queryStringPattern
    .split('&')
    .reduce((previousValue, currentValue) => {
      const [key, value] = currentValue.split('=');
      let _value = value || '';
      if (_value.indexOf(':') === 0) {
        _value = values[value.substring(1)] || '';
      }
      previousValue.push(`${key}=${_value}`);
      return previousValue;
    }, [] as string[]);
  return queryStringPatternSplitAmpersand.join('&');
};

import type { InterfaceReplacePathnameDynamicValues } from '../@types/interfaces/replacePathnameDynamicValues';

export const replacePathnameDynamicValues = ({
  pathnamePattern,
  values,
}: InterfaceReplacePathnameDynamicValues) => {
  if (pathnamePattern.indexOf('/') !== 0) {
    return undefined;
  }
  const replacedPathnamePattern = pathnamePattern
    .split('/')
    .reduce((previousValue, currentValue) => {
      let pushValue = currentValue;
      if (currentValue.indexOf(':') === 0) {
        currentValue.substring(1);
        pushValue = values[currentValue.substring(1)];
      }
      previousValue.push(pushValue);
      return previousValue;
    }, [] as string[]);
  return replacedPathnamePattern.join('/');
};

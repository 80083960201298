import React, { useRef, useEffect, useState } from 'react';
import { debounce } from 'debounce';

import type {
  InterfaceBroadcastDimensions,
  InterfaceGenerateIframe,
  iframeOnLoad,
} from '../../@types/interfaces/GenerateIframe';

const broadcastDimensions = ({ width, height, contentWindow }: InterfaceBroadcastDimensions) => {
  if (contentWindow && width && height) {
    contentWindow.postMessage(
      {
        width,
        height,
      },
      '*',
    );
  }
};

const iframeOnLoad = ({
  src,
  trackingService,
  broadcastDimensionsArg,
  analytics,
}: iframeOnLoad) => {
  if (analytics && (analytics?.vendorName || analytics?.vendorType)) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'iframe_load',
          src,
          title: analytics?.vendorName || '',
          type: analytics?.vendorType || '',
        },
      },
    });
  }
  broadcastDimensions(broadcastDimensionsArg);
};

/**
 * - `preset1`: Generates iframe with all attributes provided
 * - `preset2`: Generates iframe with `src` that has merged parent url search params with child search params
 * - `preset3`: Generates iframe with `src` using 'dynamic segments'
 * - `preset4`: Generates iframe with `srcdoc`
 */
export const GenerateIframe = ({
  iframeProps,
  trackingService,
  presetSizing,
  presetIframe,
  analytics,
}: InterfaceGenerateIframe) => {
  const isSSR = typeof window === 'undefined';
  const [onFirstRender, setOnFirstRender] = useState(true);
  useEffect(() => {
    setOnFirstRender(false);
  }, []);

  const refIframe = useRef<HTMLIFrameElement>(null);

  const broadcastDimensionsArg = {
    contentWindow: refIframe?.current?.contentWindow,
    width: refIframe?.current?.offsetWidth,
    height: refIframe?.current?.offsetHeight,
  };
  const listenerResize = debounce(() => {
    broadcastDimensions(broadcastDimensionsArg);
  }, 500);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      broadcastDimensions(broadcastDimensionsArg);

      if (presetSizing === 1) {
        window?.addEventListener('resize', listenerResize);
      } else {
        window?.removeEventListener('resize', listenerResize);
      }
    }
    // cleanup/unload
    return () => {
      if (typeof window !== 'undefined') {
        window?.removeEventListener('resize', listenerResize);
      }
    };
  }, [presetSizing]);

  // NOTE: Direct tracking call when using Preset 1
  // SSR works too well for preset 1. Think since the iframe is included
  // in the source the scripting misses the onLoad event
  useEffect(() => {
    if (
      presetIframe === 1 &&
      trackingService.featureAppName &&
      analytics &&
      (analytics?.vendorName || analytics?.vendorType)
    ) {
      trackingService.track({
        event: {
          eventInfo: {
            eventAction: 'iframe_load',
            src: iframeProps.src || '',
            title: analytics?.vendorName || '',
            type: analytics?.vendorType || '',
          },
        },
      });
    }
  }, [trackingService.featureAppName]);

  const modifiedIframeProps = {
    ...iframeProps,
    ref: refIframe,
    onLoad: (event: React.SyntheticEvent<HTMLIFrameElement>) => {
      iframeOnLoad({
        src: event.currentTarget.src,
        trackingService,
        analytics,
        broadcastDimensionsArg,
      });
    },
  } as React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>;
  switch (presetIframe) {
    case 1: {
      return (
        <iframe
          {...modifiedIframeProps}
          // Unsetting the `modifiedIframeProps.onLoad`.
          // Tracking call is happening above in a `useEffect` block.
          // See above comment "NOTE: Direct tracking call when using Preset 1"
          onLoad={() => {}}
          title={modifiedIframeProps.title}
        />
      );
    }
    case 2: {
      return isSSR || onFirstRender ? null : (
        <iframe {...modifiedIframeProps} title={modifiedIframeProps.title} />
      );
    }
    case 3: {
      return isSSR || onFirstRender ? null : (
        <iframe {...modifiedIframeProps} title={modifiedIframeProps.title} />
      );
    }
    case 4: {
      // NOTE: no need for tracking calls when people are writing html/css/js to iframe > srcdoc
      return isSSR || onFirstRender ? null : (
        <iframe
          {...modifiedIframeProps}
          title={modifiedIframeProps.title}
          // unset src
          src={undefined}
          srcDoc={iframeProps.srcDoc}
          // unset onLoad
          onLoad={() => {}}
        />
      );
    }
    default:
      return null;
  }
};

/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';

import type { FeatureAppDefinition, FeatureAppEnvironment } from '@feature-hub/core';
import type { ReactFeatureApp } from '@feature-hub/react';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import type { FeatureServiceDependencies } from '../@types/types/FeatureHubAppDefinition';

import { FeatureApp } from './FeatureApp';

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      'gfa:locale-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
    externals: {
      react: '*',
      'styled-components': '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'gfa:service-config-provider': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const {
      'audi-content-service': contentService,
      's2:logger': loggerService,
      'gfa:service-config-provider': configProvider,
      'audi-render-mode-service': renderModeService,
    } = featureServices;

    loggerService?.info('Feature App created.');

    const trackingService = featureServices['audi-tracking-service'];

    // SSR
    // --------------------------------------------------------------------
    const asyncSSRManager = featureServices['s2:async-ssr-manager'];
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    // on the server
    if (asyncSSRManager) {
      asyncSSRManager.scheduleRerender(
        (async () => {
          // eslint-disable-next-line
          const content = await contentService?.getContent();
          serializedStateManager?.register(() => JSON.stringify(content));
        })(),
      );
    }

    return {
      render: () => (
        <UniversalEditorProvider
          contentService={contentService}
          renderModeService={renderModeService}
        >
          <FeatureApp
            configProvider={configProvider}
            trackingService={trackingService}
            contentService={contentService}
          />
        </UniversalEditorProvider>
      ),
    };
  },
};

export default featureAppDefinition;
